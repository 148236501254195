interface DotNetStreamReference {
    arrayBuffer(): Promise<ArrayBuffer>;
}

class FileFunctions {

    public OpenFile(content: Uint8Array, contentType: string, target?: '_blank' | '_self'): void {

        // Create the URL
        const blob = new Blob([content], { type: contentType });
        const blobURL = URL.createObjectURL(blob);

        // Open the URL in a new tab
        window.open(blobURL, target);
    }

    public async OpenFileStreamAsync(contentStreamReference: DotNetStreamReference, contentType: string, target?: '_blank' | '_self'): Promise<void> {

        const content = await contentStreamReference.arrayBuffer();

        // Create the URL
        const blob = new Blob([content], { type: contentType });
        const blobURL = URL.createObjectURL(blob);

        // Open the URL in a new tab
        window.open(blobURL, target);
    }
}

class DownloadFunctions {

    public BlazorDownloadFileFast(fileName: string, contentType: string, content: Uint8Array): void {
        this.BlazorDownloadFile(fileName, contentType, content);
    }

    public BlazorDownloadFile(fileName: string, contentType: string, content: Uint8Array, target?: '_blank' | '_self'): void {

        // Create the URL
        const file = new File([content], fileName, { type: contentType });
        const fileURL = URL.createObjectURL(file);

        // Create the <a> element and click on it
        const a = document.createElement("a");
        document.body.appendChild(a);

        a.href = fileURL;
        a.download = fileName;
        a.target = target ?? '_self';
        a.click();

        // We don't need to keep the url, let's release the memory
        URL.revokeObjectURL(fileURL);
    }

    public async BlazorDownloadFileFromStream(fileName: string, contentStreamReference: DotNetStreamReference, target?: '_blank' | '_self'): Promise<void> {
        const arrayBuffer = await contentStreamReference.arrayBuffer();
        const blob = new Blob([arrayBuffer]);
        const blobURL = URL.createObjectURL(blob);

        const a = document.createElement('a');

        a.href = blobURL;
        a.download = fileName ?? '';
        a.target = target ?? '_self';
        a.click();
        a.remove();

        // We don't need to keep the url, let's release the memory
        URL.revokeObjectURL(blobURL);
    }
}

class PrintingFunctions {

    public PrintW(): void {
        window.print();
    }
}

class AzureMapFunctions {

    private _dotNetRef?: DotNet.DotNetObject;

    public SetTokenProvider(dotNetRef?: DotNet.DotNetObject): void {
        this._dotNetRef = dotNetRef;
    }

    public async GetAzureMapsTokenAsync(): Promise<string|null> {
        if (this._dotNetRef != null) {
            return await this._dotNetRef.invokeMethodAsync<string|null>('GetAzureMapsTokenAsync');
        }
        return null;
    }

    public ResizeMap(): void {
        const map: AzureMap = window.azureMapsControl.Core.getMap();
        if (map) {
            map.resize();
        }
    }
}

window.AzureMapFunctions = new AzureMapFunctions();
window.DownloadFunctions = new DownloadFunctions();
window.FileFunctions = new FileFunctions();
window.PrintingFunctions = new PrintingFunctions();

export { }
